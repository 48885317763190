import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/react/raul/raulPortfolio/src/components/layoutmdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Crea una API con Typescript, Node, Express y MySQL`}</h1>
    <h2>{`Instalaciones y configuraciones`}</h2>
    <p>{`Empezamos ejecutando el siguiente comando:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-terminal"
      }}>{`npm init -y
`}</code></pre>
    <p>{`Esto nos crea un package.json que permite configurar nuestra app y sus dependencias para funcionar.Instalamos las dependencias.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-terminal"
      }}>{`npm i -D typescript nodemon ts-node @types/express @types/morgan types/mysql2
npm i express morgan mysql2
`}</code></pre>
    <p>{`Volviendo al package.json, añadiremos dos scripts:`}</p>
    <p>{`"build": "tsc",`}</p>
    <p>{`"dev": "nodemon src/index.ts --exec ts-node"`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`npm run build:`}</em>{` compila todo el código y lo transpila a Javascript.`}</li>
      <li parentName="ul"><em parentName="li">{`npm run dev:`}</em>{` ejecuta un live server que se actualizará cada vez que se realize un cambio en el código.`}</li>
    </ul>
    <p>{`Ahora debemos crear el archivo que nos permitirá configurar nuestro código Typescript:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-terminal"
      }}>{`npx typescript --init
`}</code></pre>
    <p>{`Accedemos al archivo tsconfig.json y hacemos los siguientes cambios:`}</p>
    <ul>
      <li parentName="ul">{`target: "ec6" (Habilita Ecmascript 6)`}</li>
      <li parentName="ul">{`outDir: "./dist" (indica donde se convertirá todo el código TS)`}</li>
    </ul>
    <h2>{`Desarrollo`}</h2>
    <p>{`Para comenzar con nuestra API crearemos en la raíz una carpeta src donde tendremos toda nuestra aplicación. El primer archivo de todos será un `}<strong parentName="p">{`index.ts`}</strong>{` que se encargará de arrancar la app y la própia aplicacion en otro archivo llamado `}<strong parentName="p">{`app.ts`}</strong>{`.`}</p>
    <h3>{`app.ts`}</h3>
    <p>{`Lo primero de todo es importar express para poder construirla, crearemos una clase llamada App que contendrá dentro de su constructor todos los atributos necesarios y sus métodos.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import express, { Application } from "express"

export class App {
  private app: Application

  constructor(private port?: number | string) {
    this.app = express()
    this.setting()
  }

  setting() {
    this.app.set("port", this.port || process.env.PORT || 3000)
  }

  async listen() {
    await this.app.listen(this.app.get("port"))
    console.log("Server on port ", this.app.get("port"))
  }
}
`}</code></pre>
    <ul>
      <li parentName="ul">{`this.app() = express(): devuelve un objeto Application que nos permitirá utilizar nuestra aplicación.`}</li>
      <li parentName="ul">{`this.settings(): permite utilizar los ajustes personalizados en los métodos.`}</li>
    </ul>
    <p>{`En la llamada a settings hemos configurado la variable `}<em parentName="p">{`port`}</em>{` para utilizar una variable de entorno o en su defecto asignar el puerto 3000. Por último llamamos al método listen que levanta el servidor y permanece a la escucha de peticiones.`}</p>
    <p>{`Para que todo se ejecute debemos crear la función main que invocará nuestra App y la ejecutará dentro de `}<strong parentName="p">{`index.ts`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { App } from "./app"

async function main() {
  const app = new App()
  await app.listen()
}

main()
`}</code></pre>
    <h3>{`Middlewares`}</h3>
    <p>{`Añadiremos los siguientes middlewares para poder utilizar morgan y que el servidor acepte las respuestas de tipo Json, éstos métodos formaran parte de la clase App:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import morgan from 'morgan'

middlewares(){
        this.app.use(morgan('dev'))
        this.app.use(express.json())
    }
`}</code></pre>
    <h3>{`Rutas`}</h3>
    <p>{`Empezaremos a crear nuestra primera ruta, la raíz "/". Para tener un código limpio creamos una carpeta llama routes donde añadiremos un nuevo archivo para cada tipo de ruta. La primera será `}<strong parentName="p">{`index.route.ts`}</strong>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { Router } from "express"

const router = Router()

router.route("/").get(indexWelcome)

export default router
`}</code></pre>
    <p>{`Lo que estamos haciendo es crear un elemento Router que gestionara la llamada a la ruta asignada, en este caso la raíz. Una vez se haga una llamada a esa dirección se ejecutará la función `}<strong parentName="p">{`indexWelcome`}</strong>{`. Para la mayor legilibilidad separaremos la lógica de las rutas, para ello crearemos otra carpeta llamada `}<strong parentName="p">{`controllers`}</strong>{`. Dentro de controllers crearemos nuestro primer controlador `}<strong parentName="p">{`index.controller.ts`}</strong>{` que se encargará de responder al cliente que haya realizado una llamada a la raíz de nuestra API:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { Request, Response } from "express"

export function indexWelcome(req: Request, res: Response): Response {
  return res.json("Welcome to my API")
}
`}</code></pre>
    <p>{`En este caso devolveremos un texto en formato json dándo la bienvenida al usuario. Ahora debemos importar la lógica del controlador dentro de la ruta para poder ejectuarla, el resultado de nuestra ruta `}<strong parentName="p">{`index.routes.ts`}</strong>{` quedará de la siguiente forma:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { Router } from "express"
import { indexWelcome } from "../controllers/index.controller"

const router = Router()

router.route("/").get(indexWelcome)

export default router
`}</code></pre>
    <h2>{`Crear la base de datos`}</h2>
    <p>{`Primero de todo debemos instalar MySQL, en este post no vamos a explicar como hacerlo pero es relativamenta fácil (`}<a parentName="p" {...{
        "href": "https://www.mysql.com/downloads/"
      }}>{`https://www.mysql.com/downloads/`}</a>{`). Una vez lo tengamos instalado crearemos una nueva base de datos y una tabla donde persistirán todos los posts.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sql"
      }}>{`CREATE DATABASE node_mysql_ts;

CREATE TABLE posts(
    id INT(11) NOT NULL AUTO_INCREMENT PRIMARY KEY,
    title VARCHAR(200) NOT NULL,
    description TEXT NOT NULL,
    image_url TEXT,
    created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
);

DESCRIBE post;
`}</code></pre>
    <h3>{`Modelo de Post`}</h3>
    <p>{`Para trabajar con Typescript debemos de crear una `}<em parentName="p">{`interface`}</em>{` de post para saber que atributos esperaremos. De nuevo creamos otra carpeta llamada `}<strong parentName="p">{`interface`}</strong>{` y dentro de ella el archivo `}<strong parentName="p">{`Post.interface.ts`}</strong>{`, que al igual que en el código SQL describimos lo siguiente:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`export interface IPost {
  id?: String
  title: String
  description: String
  image_url: String
  created_at: Date
}
`}</code></pre>
    <h3>{`Conexión con la base de datos`}</h3>
    <p>{`Para interactuar con nuestra base de datos debemos de crear una conexión entre la aplicación y ésta. En el directorio raíz crearemos un nuevo archivo `}<strong parentName="p">{`database.ts`}</strong>{`. En él crearemos una conexión con la siguiente configuración:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { createPool } from "mysql2/promise"

export async function connect() {
  const connection = await createPool({
    host: "localhost",
    user: "root",
    password: "root",
    database: "node_mysql_ts",
    connectionLimit: 10,
  })

  return connection
}
`}</code></pre>
    <p>{`El objeto que devolvemos és el encargado de hacer las conexiones.`}</p>
    <h2>{`Rutas a Post`}</h2>
    <p>{`Para manejar todas las rutas relacionadas con los posts creamos un nuevo archivo `}<strong parentName="p">{`src/routes/posts.routes.ts`}</strong>{`. Dentro añadiremos dos rutas:`}</p>
    <p>{`/posts`}</p>
    <ul>
      <li parentName="ul">{`get: Obtiene todos los posts de la DB`}</li>
      <li parentName="ul">{`post: Crea un nuevo post en la DB`}</li>
    </ul>
    <p>{`/posts/:postId`}</p>
    <ul>
      <li parentName="ul">{`get: Obtiene el post con la id indicada`}</li>
      <li parentName="ul">{`delete: Elimina el post con la id indicada`}</li>
      <li parentName="ul">{`put: Actualiza el post indicado`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { Router } from "express"
import {
  createPost,
  getPosts,
  getPost,
  deletePost,
  updatePost,
} from "../controllers/posts.controller"

const router = Router()

router.route("/posts").get(getPosts).post(createPost)

router.route("/posts/:postId").get(getPost).delete(deletePost).put(updatePost)

export default router
`}</code></pre>
    <p>{`Por último crearemos los controladores de cada una de las peticiones en `}<strong parentName="p">{`/src/controllers/posts.controllers.ts`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { Request, Response } from "express"

import { connect } from "../database"

import { IPost } from "../interface/Post.interface"

export async function getPosts(req: Request, res: Response): Promise<Response> {
  const db = await connect()
  const posts = await db.query("SELECT * FROM posts")
  return res.json(posts)
}

export async function createPost(req: Request, res: Response) {
  const newPost: IPost = req.body
  const db = await connect()
  await db.query("INSERT INTO posts SET ?", [newPost])
  return res.json({
    message: "Post Created",
  })
}

export async function getPost(req: Request, res: Response): Promise<Response> {
  const id = req.params.postId
  const db = await connect()
  const post = await db.query("SELECT * FROM posts WHERE id = ?", [id])
  return res.json(post[0])
}

export async function deletePost(
  req: Request,
  res: Response
): Promise<Response> {
  const id = req.params.postId
  const db = await connect()
  const post = await db.query("DELETE FROM posts WHERE id = ?", [id])
  return res.json({
    message: "Post deleted",
  })
}

export async function updatePost(
  req: Request,
  res: Response
): Promise<Response> {
  const id = req.params.postId
  const updatePost: IPost = req.body
  const db = await connect()
  const post = await db.query("UPDATE posts set ?  WHERE id = ?", [
    updatePost,
    id,
  ])
  return res.json({
    message: "Post updated",
  })
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      